.logoImage {
  height: 3em;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .logoImage {
    animation: logo-spin infinite 5s linear;
  }
}

@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.logo {
  display: flex;
  direction: ltr;
}

.logoCaption {
  font-weight: bold;
  font-size: xx-large;
  color: black;
}

.root {
  background-color: #6bd2b3;
  display: flex;
}

.menu {
  list-style-type: none;
  background-color: transparent;
}

.menu li a {
  display: block;
  color: black;
  text-align: center;
  padding: 16px;
  text-decoration: none;
  width: fit-content;
}

.menu li a:hover {
  color: white;
}